import { Injectable } from "@angular/core";
import { HttpClient, HttpBackend } from "@angular/common/http";
import { environment } from "../../../../environments/environment";

@Injectable()
export class HeaderService {
  private httpClient: HttpClient;

  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  url = environment.hostUrl;
  getBuildData(repo, branch) {
    return this.httpClient.get(
      this.url + "rtb/tools/build/latest?repository="+repo+"&branch="+branch
    );
  }
}
