import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { CookieService } from 'ngx-cookie-service';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
    constructor(private cookieService:CookieService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request to add the new header.
        // console.log("intercepted request ... ");        

        var authReq = req.clone();

        if(req.headers.has('DoNotIntercept')) {
            authReq = authReq.clone({
              headers: authReq.headers.delete(
                'DoNotIntercept'
              )
            });
            return next.handle(authReq).catch(
                (error, caught) => {
                    console.log("Error Occurred in 'DoNotIntercept'");
                    console.log(error);
                    return Observable.throw(error);
                }
            ) as any;
          }
          else{
              // do some changes with the request as required
              authReq = req.clone(
                {
                    headers: req.headers.set("X-XSRF-TOKEN", this.cookieService.get("adry_csrf")),
                    withCredentials: true
                }
              );
            //   return next.handle(authReq);
            //send the newly created request
            return next.handle(authReq)
            .catch((error, caught) => {
                //intercept the respons error and displace it to the console 
                console.log("Error Occurred in Interceptor");
                console.log(error);
                //return the error to the method that called it
                return Observable.throw(error);
            }) as any;
          }        
    }
}