import { Component, OnInit } from '@angular/core';
import { CompanyLoginService } from './company-login.service';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import jwt_decode from "jwt-decode";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'ngx-company-login',
  templateUrl: './company-login.component.html',
  styleUrls: ['./company-login.component.scss']
})
export class CompanyLoginComponent implements OnInit {

  error = false;
  success = false;
  errorMessage = "";
  user = {
    email: "",
    password: "",
  }

  constructor(
    private loginService: CompanyLoginService,
    private authService: NbAuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    if (window.localStorage) {
      window.localStorage.clear();
    }
  }

  login() {

    let role = "";
    this.activatedRoute.queryParams.subscribe(
      params => {
        if (params["role"] == undefined) {
          this.error = true;
          this.success = false;
          this.errorMessage = "No roles found.";
          return;

        } else {
          role = params["role"];

          this.loginService.postData(this.user, role).subscribe(
            (res: any) => {
              this.success = true;
              this.error = false;

              if (window.localStorage) {
                window.localStorage.setItem("auth_app_token", JSON.stringify({
                  name: "nb:auth:jwt:token",
                  ownerStrategyName: "email",
                  value: res.access_token,
                  createdAt: Date.now()
                }));

                var decoded: any = jwt_decode(res.access_token);
                if (decoded.company_role.length == 0) {
                  this.error = true;
                  this.success = false;
                  this.errorMessage = "No roles assigned.";
                  return;
                }

                window.localStorage.setItem("USER", JSON.stringify({
                  company_role: decoded.company_role,
                  exp: decoded.exp,
                  full_name: decoded.full_name,
                  id: decoded.id,
                  role: role,
                }));

                if (role == 'advertiser') {
                  this.router.navigateByUrl('/pages/advertiser-dashboard');
                } else if (role == 'publisher') {
                  this.router.navigateByUrl('/pages/publisher-dashboard');
                }
              }
            },
            err => {
              this.success = false;
              this.error = true;
              this.errorMessage = err.error;
            }
          )
        }
      }
    );
  }
}
