import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService, NbWindowModule, NbListModule, NbWindowService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HeaderService } from "./header.service";

import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  providers: [HeaderService],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('contentTemplate', { static: false }) contentTemplate: TemplateRef<any>;

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  build: any;
  buildList: [];

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [
    // { title: 'Profile' },
    { title: 'Log out', link: '/auth/logout' }
  ];

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private authService: NbAuthService,
    private headerService: HeaderService,
    private windowService: NbWindowService,
  ) {
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload(); // here we receive a payload from the token and assigne it to our `user` variable 
          // console.log("THSIUSER",this.user);
          if (window.localStorage) {
            window.localStorage.setItem("USER", JSON.stringify(this.user));
          }
        } else {
          this.setUserData();
        }
      });

  }

  ngOnInit() {
    this.build = {
      id: "",
      timestamp: "",
    };
    this.buildList = [];

    this.currentTheme = this.themeService.currentTheme;

    // this.userService.getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.getBuildData("ngx-rtb-backend", "master");
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(false, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  setUserData() {
    let data: any = JSON.parse(window.localStorage.getItem("USER"));
    if (data) {
      this.user = data;
    } else {
      console.log('USER is empty in header.component');
    }
  }

  getBuildData(repo, branch) {
    this.headerService.getBuildData(repo, branch).subscribe(
      (data) => {
        this.buildList = data["data"];
        this.build.id = data["data"][0]["commit_id"];
        this.build.timestamp = data["data"][0]["timestamp"];
      },
      (err) => {
        console.error(err);
      }
    );
  }

  openWindow() {
    this.windowService.open(this.contentTemplate);
  }

}
