import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: 'root'
})
export class CompanyLoginService {

  constructor(private http: HttpClient) { }

  url = environment.hostUrl;

  postData(body, role) {
    return this.http.post(`${this.url}rtb/companies/login?role=${role}`, body);
  }

}
